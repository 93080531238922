import {CommonModule} from '@angular/common';
import {ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {ButtonModule} from 'primeng/button';

import {ButtonType} from '../../../types/button.type';
import {UiCommonButtonDirective} from '../common-button/ui-common-button.directive';

@Component({
    selector: 'ui-button',
    templateUrl: './basic-button.component.html',
    styleUrls: ['./basic-button.component.scss'],
    standalone: true,
    imports: [MatButtonModule, CommonModule, ButtonModule],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.Emulated,
})
export class BasicButtonSComponent extends UiCommonButtonDirective implements OnInit {
    @Input()
    text!: string;

    @Input()
    type: ButtonType = 'flat';

    @Input()
    stretch: 'true' | undefined;
}
