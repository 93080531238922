import {Component, Input, OnInit} from '@angular/core';

interface ITeamCard {
    name: string;
    role: string;
    description: string;
    image: string;
}

@Component({
    selector: 'app-team-block',
    templateUrl: './team-block.component.html',
    styleUrls: ['./team-block.component.scss'],
})
export class TeamBlockComponent implements OnInit {
    @Input()
    hasThaiPerson: boolean | undefined;

    cards: ITeamCard[] = [];

    ngOnInit(): void {
        this.cards = this.getCards(!!this.hasThaiPerson);
    }

    private getCards(hasThaiPerson: boolean): ITeamCard[] {
        const cards: ITeamCard[] = [
            {
                name: 'TEAM_BLOCK.CARD_1.NAME',
                role: 'TEAM_BLOCK.CARD_1.ROLE',
                description: 'TEAM_BLOCK.CARD_1.DESCRIPTION',
                image: 'assets/img/ava_1.png',
            },
            {
                name: 'TEAM_BLOCK.CARD_2.NAME',
                role: 'TEAM_BLOCK.CARD_2.ROLE',
                description: 'TEAM_BLOCK.CARD_2.DESCRIPTION',
                image: 'assets/img/ava_2.png',
            },
            {
                name: 'TEAM_BLOCK.CARD_3.NAME',
                role: 'TEAM_BLOCK.CARD_3.ROLE',
                description: 'TEAM_BLOCK.CARD_3.DESCRIPTION',
                image: 'assets/img/ava_3.png',
            },
            {
                name: 'TEAM_BLOCK.CARD_4.NAME',
                role: 'TEAM_BLOCK.CARD_4.ROLE',
                description: 'TEAM_BLOCK.CARD_4.DESCRIPTION',
                image: 'assets/img/ava_4.png',
            },
            {
                name: 'TEAM_BLOCK.CARD_5.NAME',
                role: 'TEAM_BLOCK.CARD_5.ROLE',
                description: 'TEAM_BLOCK.CARD_5.DESCRIPTION',
                image: 'assets/img/ava_5.png',
            },
            {
                name: 'TEAM_BLOCK.CARD_6.NAME',
                role: 'TEAM_BLOCK.CARD_6.ROLE',
                description: 'TEAM_BLOCK.CARD_6.DESCRIPTION',
                image: 'assets/img/ava_6.png',
            },
            {
                name: 'TEAM_BLOCK.CARD_7.NAME',
                role: 'TEAM_BLOCK.CARD_7.ROLE',
                description: 'TEAM_BLOCK.CARD_7.DESCRIPTION',
                image: 'assets/img/ava_7.png',
            },
        ];

        if (hasThaiPerson) {
            const el = {
                name: 'TEAM_BLOCK.CARD_8.NAME',
                role: 'TEAM_BLOCK.CARD_8.ROLE',
                description: 'TEAM_BLOCK.CARD_8.DESCRIPTION',
                image: 'assets/img/ava_8.png',
            };

            cards.splice(1, 0, el);
        }

        return cards;
    }
}
