import {LoggerMessageTypeEnum} from '../interfaces/logger-message-type.enum';
import {LoggerMessage} from '../logger-message.class';
import {AbstractLoggerProvider} from './_abstract-logger.provider';

export class ConsoleLoggerProvider extends AbstractLoggerProvider {
    massageHandler(error: LoggerMessage) {
        switch (error.props.type) {
            case LoggerMessageTypeEnum.InternalInfo:
            case LoggerMessageTypeEnum.DevInfo:
                console.info(error.massage);
                break;
            case LoggerMessageTypeEnum.InternalWarn:
            case LoggerMessageTypeEnum.DevWarn:
                console.warn(error.massage);
                break;
            case LoggerMessageTypeEnum.InternalError:
            case LoggerMessageTypeEnum.DevError:
                console.error(error.massage);
                break;
            case LoggerMessageTypeEnum.InternalCriticalError:
            case LoggerMessageTypeEnum.DevCriticalError:
                console.error('CRITICAL ERROR!');
                console.error(error.massage);
                break;
            case LoggerMessageTypeEnum.Trace:
            case LoggerMessageTypeEnum.AnalyticTrace:
                // not used
                break;
        }
    }
}
