import {Component} from '@angular/core';

interface IQuestion {
    question: string;
    answer: string;
}

@Component({
    selector: 'app-faq-block',
    templateUrl: './faq-block.component.html',
    styleUrls: ['./faq-block.component.scss'],
})
export class FaqBlockComponent {
    questions: IQuestion[] = [
        {
            question: 'QUESTION_BLOCK.QUESTION_1.QUESTION',
            answer: 'QUESTION_BLOCK.QUESTION_1.ANSWER',
        },
        {
            question: 'QUESTION_BLOCK.QUESTION_2.QUESTION',
            answer: 'QUESTION_BLOCK.QUESTION_2.ANSWER',
        },
        {
            question: 'QUESTION_BLOCK.QUESTION_3.QUESTION',
            answer: 'QUESTION_BLOCK.QUESTION_3.ANSWER',
        },
    ];
}
