import {Environment} from '@libs/utils/shared';
import {NgxsReduxDevtoolsPluginModule} from '@ngxs/devtools-plugin';
import {NgxsLoggerPluginModule} from '@ngxs/logger-plugin';
import {NgxsStoragePluginModule} from '@ngxs/storage-plugin';
import {NgxsModule} from '@ngxs/store';
import {StateClass} from '@ngxs/store/internals';

export function createNGXSModules(env: Environment<unknown>, modules: StateClass[]) {
    return [
        NgxsModule.forRoot(modules),
        NgxsReduxDevtoolsPluginModule.forRoot({
            disabled: env.production,
        }),
        NgxsLoggerPluginModule.forRoot({
            disabled: true,
            collapsed: true,
        }),
        NgxsStoragePluginModule.forRoot({
            key: [],
        }),
    ];
}
