import {Component} from '@angular/core';

interface INewFeatures {
    list: string;
}

@Component({
    selector: 'app-roadmap-block',
    templateUrl: './roadmap-block.component.html',
    styleUrls: ['./roadmap-block.component.scss'],
})
export class RoadmapBlockComponent {
    newFeatures: INewFeatures[] = [
        {
            list: 'NEW_FEATURES_BLOCK.FEATURE_1.LIST',
        },
        {
            list: 'NEW_FEATURES_BLOCK.FEATURE_2.LIST',
        },
    ];
}
