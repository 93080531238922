import {ChangeDetectionStrategy, Component, EventEmitter, Output} from '@angular/core';
import {FormControl, ReactiveFormsModule, Validators} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {ButtonModule} from 'primeng/button';
import {InputTextModule} from 'primeng/inputtext';

@Component({
    selector: 'app-register-by-email',
    templateUrl: './register-by-email.component.html',
    styleUrls: ['./register-by-email.component.scss'],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [TranslateModule, ReactiveFormsModule, ButtonModule, InputTextModule],
})
export class RegisterByEmailComponent {
    email: FormControl = new FormControl(undefined, [Validators.required, Validators.email]);

    @Output()
    onPushEmail: EventEmitter<{email: string}> = new EventEmitter<{
        email: string;
    }>();

    submit(): void {
        // TODO add validation
        this.onPushEmail.emit({
            email: this.email.value,
        });
    }
}
