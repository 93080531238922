import {IAppConfigState} from './app-config-state.interface';

export namespace AppConfigActions {
    export class GetAppConfig {
        static readonly type: string = `[AppConfig] Get application configuration.`;
    }

    export class SetAppConfig {
        static readonly type: string = `[AppConfig] Set application configuration.`;

        constructor(public payload: IAppConfigState['cmsSettings']) {}
    }
}
