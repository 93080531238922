<div [innerHTML]="'GAMES_BLOCK.TITLE' | translate"
     class="games-title"></div>
<div [innerHTML]="'GAMES_BLOCK.SUBTITLE' | translate"
     class="games-subtitle"></div>
<div class="content-row games-content-row">
    <ng-container *ngFor="let gameImage of gameImages">
        <div class="content-col games-content-col">
            <img class="games-img"
                 [src]="gameImage.image" alt=""></div>
    </ng-container>
</div>
<div [innerHTML]="'GAMES_BLOCK.SUBTITLE_2' | translate"
     class="games-subtitle"></div>

