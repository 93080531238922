<div class="inner-container">
    <ng-container *ngFor="let feature of features">
        <div class="feature__container">
            <div class="feature__icon-container" [ngStyle]="{'background-image': getImageCssUrl(feature.image)}"></div>
            <div class="feature__text-container">
                <div class="feature__title" [innerHTML]="feature.title | translate"></div>
                <ul class="feature__list" [innerHTML]="feature.list | translate"></ul>
                <div class="feature__cta-str" [innerHTML]="feature.ctaStr | translate"></div>
            </div>
        </div>
    </ng-container>
</div>
