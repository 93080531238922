import {IEnvironment} from './environment.interface';
import { LoggerTypeEnum } from '../../../../logger/src';

export class Environment<ProjectSettings> {
    readonly projectSettings: ProjectSettings;
    readonly loggerType: LoggerTypeEnum;
    readonly production: boolean;
    readonly sdkBaseUrl: string;

    constructor(config: IEnvironment<ProjectSettings>) {
        this.sdkBaseUrl = config.sdkBaseUrl;
        this.projectSettings = config.projectSettings ?? ({} as ProjectSettings);
        this.loggerType = config.loggerType ?? LoggerTypeEnum.Console;
        this.production = config.production ?? true;
    }
}
