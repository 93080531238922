import {isDevMode} from '@angular/core';

import {LoggerMessage} from '../logger-message.class';

export abstract class AbstractLoggerProvider {
    protected isDev: boolean = isDevMode();

    logMassages(...errors: LoggerMessage[]): void {
        errors.forEach(error => {
            this.massageHandler(error);
        });
    }

    protected abstract massageHandler(error: LoggerMessage): void;
}
