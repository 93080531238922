<div [innerHTML]="'NEW_FEATURES_BLOCK.TITLE' | translate"
     class="new-features-title"></div>
<div class="content-row">
    <ng-container *ngFor="let newFeature of newFeatures">
        <div class="content-col">
            <ul [innerHTML]="newFeature.list | translate"
                class="new-features-list">
            </ul>
        </div>
    </ng-container>
</div>
<div class="new-features-image">
    <img src="assets/img/screenshots.png" alt="">
</div>
<div [innerHTML]="'NEW_FEATURES_BLOCK.TEXT' | translate"
     class="new-features-text"></div>
