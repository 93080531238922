<div class="input-container">
       <input class="input-field"
              type="email"
              pInputText [formControl]="email"
              placeholder="{{'LIBS.MODALS.REGISTRATION_MODAL.INPUTS.EMAIL-LABEL' | translate}}">
</div>

<div class="login-button-container">
    <p-button styleClass="p-button-filled--blue p-button-l"
              (click)="submit()"
              [disabled]="email.invalid || email.pending"
              class="login-button">{{'LIBS.MODALS.REGISTRATION_MODAL.BUTTONS.SUBMIT' | translate}}</p-button>
</div>

