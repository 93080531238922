/**
 * ClanHall API
 * ClanHall API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: admin@clanhall.net
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import {Inject, Injectable, Optional} from '@angular/core';
import {
    HttpClient,
    HttpHeaders,
    HttpParams,
    HttpResponse,
    HttpEvent,
    HttpParameterCodec,
    HttpContext,
} from '@angular/common/http';
import {CustomHttpParameterCodec} from '../encoder';
import {Observable} from 'rxjs';

// @ts-ignore
import {LoginResponse200SDKModel} from '../model/loginResponse200.sdk.model';
// @ts-ignore
import {LogoutResponse200SDKModel} from '../model/logoutResponse200.sdk.model';
// @ts-ignore
import {MeResponseSDKModel} from '../model/meResponse.sdk.model';
// @ts-ignore
import {RegisterResponse422SDKModel} from '../model/registerResponse422.sdk.model';
// @ts-ignore
import {Response200SDKModel} from '../model/response200.sdk.model';
// @ts-ignore
import {Response201SDKModel} from '../model/response201.sdk.model';
// @ts-ignore
import {Response400SDKModel} from '../model/response400.sdk.model';
// @ts-ignore
import {Response401SDKModel} from '../model/response401.sdk.model';

// @ts-ignore
import {BASE_PATH, COLLECTION_FORMATS} from '../variables';
import {Configuration} from '../configuration';

export interface LoginRequestParams {
    email?: string;
    password?: string;
}

export interface LoginByEmailTokenRequestParams {
    userId?: number;
    token?: string;
}

export interface RegisterRequestParams {
    email?: string;
    uuid?: string;
    /** Flag if user accept offer */
    offer?: boolean;
    /** Flag if user accept privacy */
    privacy?: boolean;
}

export interface SendEmailTokenRequestParams {
    email: string;
}

@Injectable({
    providedIn: 'root',
})
export class AuthSDKService {
    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(
        protected httpClient: HttpClient,
        @Optional() @Inject(BASE_PATH) basePath: string,
        @Optional() configuration: Configuration,
    ) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }

    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === 'object' && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === 'object') {
            if (Array.isArray(value)) {
                (value as any[]).forEach(elem => (httpParams = this.addToHttpParamsRecursive(httpParams, elem, key)));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substr(0, 10));
                } else {
                    throw Error('key may not be null if value is Date');
                }
            } else {
                Object.keys(value).forEach(
                    k =>
                        (httpParams = this.addToHttpParamsRecursive(
                            httpParams,
                            value[k],
                            key != null ? `${key}.${k}` : k,
                        )),
                );
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error('key may not be null if value is not object or array');
        }
        return httpParams;
    }

    /**
     * Ready
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public login(
        requestParameters: LoginRequestParams,
        observe?: 'body',
        reportProgress?: boolean,
        options?: {httpHeaderAccept?: 'application/json'; context?: HttpContext},
    ): Observable<LoginResponse200SDKModel>;
    public login(
        requestParameters: LoginRequestParams,
        observe?: 'response',
        reportProgress?: boolean,
        options?: {httpHeaderAccept?: 'application/json'; context?: HttpContext},
    ): Observable<HttpResponse<LoginResponse200SDKModel>>;
    public login(
        requestParameters: LoginRequestParams,
        observe?: 'events',
        reportProgress?: boolean,
        options?: {httpHeaderAccept?: 'application/json'; context?: HttpContext},
    ): Observable<HttpEvent<LoginResponse200SDKModel>>;
    public login(
        requestParameters: LoginRequestParams,
        observe: any = 'body',
        reportProgress: boolean = false,
        options?: {httpHeaderAccept?: 'application/json'; context?: HttpContext},
    ): Observable<any> {
        const email = requestParameters.email;
        const password = requestParameters.password;

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = ['application/json'];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        // to determine the Content-Type header
        const consumes: string[] = ['multipart/form-data'];

        const canConsumeForm = this.canConsumeForm(consumes);

        let localVarFormParams: {append(param: string, value: any): any};
        let localVarUseForm = false;
        let localVarConvertFormParamsToString = false;
        if (localVarUseForm) {
            localVarFormParams = new FormData();
        } else {
            localVarFormParams = new HttpParams({encoder: this.encoder});
        }

        if (email !== undefined) {
            localVarFormParams = (localVarFormParams.append('email', <any>email) as any) || localVarFormParams;
        }
        if (password !== undefined) {
            localVarFormParams = (localVarFormParams.append('password', <any>password) as any) || localVarFormParams;
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.post<LoginResponse200SDKModel>(
            `${this.configuration.basePath}/api/login`,
            localVarConvertFormParamsToString ? localVarFormParams.toString() : localVarFormParams,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress,
            },
        );
    }

    /**
     * Ready
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public loginByEmailToken(
        requestParameters: LoginByEmailTokenRequestParams,
        observe?: 'body',
        reportProgress?: boolean,
        options?: {httpHeaderAccept?: 'application/json'; context?: HttpContext},
    ): Observable<LoginResponse200SDKModel>;
    public loginByEmailToken(
        requestParameters: LoginByEmailTokenRequestParams,
        observe?: 'response',
        reportProgress?: boolean,
        options?: {httpHeaderAccept?: 'application/json'; context?: HttpContext},
    ): Observable<HttpResponse<LoginResponse200SDKModel>>;
    public loginByEmailToken(
        requestParameters: LoginByEmailTokenRequestParams,
        observe?: 'events',
        reportProgress?: boolean,
        options?: {httpHeaderAccept?: 'application/json'; context?: HttpContext},
    ): Observable<HttpEvent<LoginResponse200SDKModel>>;
    public loginByEmailToken(
        requestParameters: LoginByEmailTokenRequestParams,
        observe: any = 'body',
        reportProgress: boolean = false,
        options?: {httpHeaderAccept?: 'application/json'; context?: HttpContext},
    ): Observable<any> {
        const userId = requestParameters.userId;
        const token = requestParameters.token;

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = ['application/json'];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        // to determine the Content-Type header
        const consumes: string[] = ['multipart/form-data'];

        const canConsumeForm = this.canConsumeForm(consumes);

        let localVarFormParams: {append(param: string, value: any): any};
        let localVarUseForm = false;
        let localVarConvertFormParamsToString = false;
        if (localVarUseForm) {
            localVarFormParams = new FormData();
        } else {
            localVarFormParams = new HttpParams({encoder: this.encoder});
        }

        if (userId !== undefined) {
            localVarFormParams = (localVarFormParams.append('user_id', <any>userId) as any) || localVarFormParams;
        }
        if (token !== undefined) {
            localVarFormParams = (localVarFormParams.append('token', <any>token) as any) || localVarFormParams;
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.post<LoginResponse200SDKModel>(
            `${this.configuration.basePath}/api/auth/email_token`,
            localVarConvertFormParamsToString ? localVarFormParams.toString() : localVarFormParams,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress,
            },
        );
    }

    /**
     * Ready
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public logout(
        observe?: 'body',
        reportProgress?: boolean,
        options?: {httpHeaderAccept?: 'application/json'; context?: HttpContext},
    ): Observable<LogoutResponse200SDKModel>;
    public logout(
        observe?: 'response',
        reportProgress?: boolean,
        options?: {httpHeaderAccept?: 'application/json'; context?: HttpContext},
    ): Observable<HttpResponse<LogoutResponse200SDKModel>>;
    public logout(
        observe?: 'events',
        reportProgress?: boolean,
        options?: {httpHeaderAccept?: 'application/json'; context?: HttpContext},
    ): Observable<HttpEvent<LogoutResponse200SDKModel>>;
    public logout(
        observe: any = 'body',
        reportProgress: boolean = false,
        options?: {httpHeaderAccept?: 'application/json'; context?: HttpContext},
    ): Observable<any> {
        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (JWTAuth) required
        localVarCredential = this.configuration.lookupCredential('JWTAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = ['application/json'];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.post<LogoutResponse200SDKModel>(`${this.configuration.basePath}/api/logout`, null, {
            context: localVarHttpContext,
            responseType: <any>responseType_,
            withCredentials: this.configuration.withCredentials,
            headers: localVarHeaders,
            observe: observe,
            reportProgress: reportProgress,
        });
    }

    /**
     * Ready
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public me(
        observe?: 'body',
        reportProgress?: boolean,
        options?: {httpHeaderAccept?: 'application/json'; context?: HttpContext},
    ): Observable<MeResponseSDKModel>;
    public me(
        observe?: 'response',
        reportProgress?: boolean,
        options?: {httpHeaderAccept?: 'application/json'; context?: HttpContext},
    ): Observable<HttpResponse<MeResponseSDKModel>>;
    public me(
        observe?: 'events',
        reportProgress?: boolean,
        options?: {httpHeaderAccept?: 'application/json'; context?: HttpContext},
    ): Observable<HttpEvent<MeResponseSDKModel>>;
    public me(
        observe: any = 'body',
        reportProgress: boolean = false,
        options?: {httpHeaderAccept?: 'application/json'; context?: HttpContext},
    ): Observable<any> {
        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (JWTAuth) required
        localVarCredential = this.configuration.lookupCredential('JWTAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = ['application/json'];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.get<MeResponseSDKModel>(`${this.configuration.basePath}/api/me`, {
            context: localVarHttpContext,
            responseType: <any>responseType_,
            withCredentials: this.configuration.withCredentials,
            headers: localVarHeaders,
            observe: observe,
            reportProgress: reportProgress,
        });
    }

    /**
     * Ready
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public register(
        requestParameters: RegisterRequestParams,
        observe?: 'body',
        reportProgress?: boolean,
        options?: {httpHeaderAccept?: 'application/json'; context?: HttpContext},
    ): Observable<Response201SDKModel>;
    public register(
        requestParameters: RegisterRequestParams,
        observe?: 'response',
        reportProgress?: boolean,
        options?: {httpHeaderAccept?: 'application/json'; context?: HttpContext},
    ): Observable<HttpResponse<Response201SDKModel>>;
    public register(
        requestParameters: RegisterRequestParams,
        observe?: 'events',
        reportProgress?: boolean,
        options?: {httpHeaderAccept?: 'application/json'; context?: HttpContext},
    ): Observable<HttpEvent<Response201SDKModel>>;
    public register(
        requestParameters: RegisterRequestParams,
        observe: any = 'body',
        reportProgress: boolean = false,
        options?: {httpHeaderAccept?: 'application/json'; context?: HttpContext},
    ): Observable<any> {
        const email = requestParameters.email;
        const uuid = requestParameters.uuid;
        const offer = requestParameters.offer;
        const privacy = requestParameters.privacy;

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = ['application/json'];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        // to determine the Content-Type header
        const consumes: string[] = ['application/x-www-form-urlencoded'];

        const canConsumeForm = this.canConsumeForm(consumes);

        let localVarFormParams: {append(param: string, value: any): any};
        let localVarUseForm = false;
        let localVarConvertFormParamsToString = false;
        if (localVarUseForm) {
            localVarFormParams = new FormData();
        } else {
            localVarFormParams = new HttpParams({encoder: this.encoder});
        }

        if (email !== undefined) {
            localVarFormParams = (localVarFormParams.append('email', <any>email) as any) || localVarFormParams;
        }
        if (uuid !== undefined) {
            localVarFormParams = (localVarFormParams.append('uuid', <any>uuid) as any) || localVarFormParams;
        }
        if (offer !== undefined) {
            localVarFormParams = (localVarFormParams.append('offer', <any>offer) as any) || localVarFormParams;
        }
        if (privacy !== undefined) {
            localVarFormParams = (localVarFormParams.append('privacy', <any>privacy) as any) || localVarFormParams;
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.post<Response201SDKModel>(
            `${this.configuration.basePath}/api/register`,
            localVarConvertFormParamsToString ? localVarFormParams.toString() : localVarFormParams,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress,
            },
        );
    }

    /**
     * Ready
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public registerByDiscordRedirect(
        observe?: 'body',
        reportProgress?: boolean,
        options?: {httpHeaderAccept?: 'application/json'; context?: HttpContext},
    ): Observable<Response200SDKModel>;
    public registerByDiscordRedirect(
        observe?: 'response',
        reportProgress?: boolean,
        options?: {httpHeaderAccept?: 'application/json'; context?: HttpContext},
    ): Observable<HttpResponse<Response200SDKModel>>;
    public registerByDiscordRedirect(
        observe?: 'events',
        reportProgress?: boolean,
        options?: {httpHeaderAccept?: 'application/json'; context?: HttpContext},
    ): Observable<HttpEvent<Response200SDKModel>>;
    public registerByDiscordRedirect(
        observe: any = 'body',
        reportProgress: boolean = false,
        options?: {httpHeaderAccept?: 'application/json'; context?: HttpContext},
    ): Observable<any> {
        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = ['application/json'];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.get<Response200SDKModel>(`${this.configuration.basePath}/api/auth/discord/redirect`, {
            context: localVarHttpContext,
            responseType: <any>responseType_,
            withCredentials: this.configuration.withCredentials,
            headers: localVarHeaders,
            observe: observe,
            reportProgress: reportProgress,
        });
    }

    /**
     * Not Ready
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public sendEmailToken(
        requestParameters: SendEmailTokenRequestParams,
        observe?: 'body',
        reportProgress?: boolean,
        options?: {httpHeaderAccept?: 'application/json'; context?: HttpContext},
    ): Observable<Response200SDKModel>;
    public sendEmailToken(
        requestParameters: SendEmailTokenRequestParams,
        observe?: 'response',
        reportProgress?: boolean,
        options?: {httpHeaderAccept?: 'application/json'; context?: HttpContext},
    ): Observable<HttpResponse<Response200SDKModel>>;
    public sendEmailToken(
        requestParameters: SendEmailTokenRequestParams,
        observe?: 'events',
        reportProgress?: boolean,
        options?: {httpHeaderAccept?: 'application/json'; context?: HttpContext},
    ): Observable<HttpEvent<Response200SDKModel>>;
    public sendEmailToken(
        requestParameters: SendEmailTokenRequestParams,
        observe: any = 'body',
        reportProgress: boolean = false,
        options?: {httpHeaderAccept?: 'application/json'; context?: HttpContext},
    ): Observable<any> {
        const email = requestParameters.email;
        if (email === null || email === undefined) {
            throw new Error('Required parameter email was null or undefined when calling sendEmailToken.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (email !== undefined && email !== null) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>email, 'email');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = ['application/json'];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.get<Response200SDKModel>(`${this.configuration.basePath}/api/auth/send_email_token`, {
            context: localVarHttpContext,
            params: localVarQueryParameters,
            responseType: <any>responseType_,
            withCredentials: this.configuration.withCredentials,
            headers: localVarHeaders,
            observe: observe,
            reportProgress: reportProgress,
        });
    }
}
