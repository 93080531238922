import {CommonModule} from '@angular/common';
import {ChangeDetectionStrategy, Component} from '@angular/core';

@Component({
    selector: 'ui-square-icon-button',
    standalone: true,
    templateUrl: './square-icon-button.component.html',
    styleUrls: ['./square-icon-button.component.scss'],
    imports: [CommonModule],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SquareIconButtonComponent {}
