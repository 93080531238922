<div class="premier-block">
    <div class="premier-block__text-container">
        <div class="premier-block__text-inner-container">
            <div class="premier-block__content-container premier-block__content-container_main-text">
                {{testTranslationsList.mainText || "PREMIER_BLOCK.MAIN_TEXT" | translate}}
            </div>
            <span class="premier-block__content-container premier-block__content-container_sub-text">
                {{testTranslationsList.subText || "PREMIER_BLOCK.SUB_TEXT" | translate}}
            </span>
            <div class="premier-block__content-container premier-block__content-container_cta">
                <ui-button [text]="testTranslationsList.ctaButton || 'PREMIER_BLOCK.CTA_BUTTON' | translate"
                           color="accent" (click)="registerFn()">
                </ui-button>
            </div>
        </div>
    </div>
</div>
