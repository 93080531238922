import {Component, Input} from '@angular/core';

interface IBenefit {
    title: string;
    subTitle: string;
    list: string;
}

@Component({
    selector: 'app-benefits-block',
    templateUrl: './benefits-block.component.html',
    styleUrls: ['./benefits-block.component.scss'],
})
export class BenefitsBlockComponent {
    @Input()
    registerFn!: Function;

    benefits: IBenefit[] = [
        {
            title: 'BENEFITS_BLOCK.BENEFIT_1.TITLE',
            subTitle: 'BENEFITS_BLOCK.BENEFIT_1.SUBTITLE',
            list: 'BENEFITS_BLOCK.BENEFIT_1.LIST',
        },
        {
            title: 'BENEFITS_BLOCK.BENEFIT_2.TITLE',
            subTitle: 'BENEFITS_BLOCK.BENEFIT_2.SUBTITLE',
            list: 'BENEFITS_BLOCK.BENEFIT_2.LIST',
        },
    ];
}
