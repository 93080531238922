import {Component} from '@angular/core';

interface IFeatures {
    subtitle: string;
    list: string;
}

@Component({
    selector: 'app-why-for-block',
    templateUrl: './why-for-block.component.html',
    styleUrls: ['./why-for-block.component.scss'],
})
export class WhyForBlockComponent {
    features: IFeatures[] = [
        {
            subtitle: 'FEATURES_BLOCK.FEATURE_1.SUBTITLE',
            list: 'FEATURES_BLOCK.FEATURE_1.LIST',
        },
        {
            subtitle: 'FEATURES_BLOCK.FEATURE_2.SUBTITLE',
            list: 'FEATURES_BLOCK.FEATURE_2.LIST',
        },
    ];
}
