import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';

import { AppSDKService } from './api/app.sdk.service';
import { AuthSDKService } from './api/auth.sdk.service';
import { CharSDKService } from './api/char.sdk.service';
import { CommSDKService } from './api/comm.sdk.service';
import { DocumentsSDKService } from './api/documents.sdk.service';
import { GameSDKService } from './api/game.sdk.service';
import { GuildSDKService } from './api/guild.sdk.service';
import { MyCharSDKService } from './api/myChar.sdk.service';
import { MyCommSDKService } from './api/myComm.sdk.service';
import { MyGuildSDKService } from './api/myGuild.sdk.service';
import { MyProfileSDKService } from './api/myProfile.sdk.service';
import { MyUnitSDKService } from './api/myUnit.sdk.service';
import { UnitSDKService } from './api/unit.sdk.service';
import { UserSDKService } from './api/user.sdk.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: []
})
export class ClanhallSDKApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ClanhallSDKApiModule> {
        return {
            ngModule: ClanhallSDKApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ClanhallSDKApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ClanhallSDKApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
