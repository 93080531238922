<app-premier-block [registerFn]="registerFn('landingStartRegTop')"
                   class="frame frame_fullscreen"></app-premier-block>
<div class="dots-wrapper">
    <app-feature-block class="frame frame_additional-margin"></app-feature-block>
    <app-questions-block class="frame frame_additional-margin"></app-questions-block>
    <app-benefits-block [registerFn]="registerFn('landingStartRegMiddle')"
                        class="frame frame_additional-margin"></app-benefits-block>
    <app-games-block class="frame frame_additional-margin"></app-games-block>
    <app-why-for-block class="frame frame_additional-margin"></app-why-for-block>
    <app-roadmap-block class="frame frame_main-margin"></app-roadmap-block>
    <app-faq-block class="frame frame_additional-margin"></app-faq-block>
    <app-team-block [hasThaiPerson]="hasThaiPerson" class="frame frame_additional-margin"></app-team-block>
    <div class="register-btn-wrapper">
        <ui-button (click)="emitRegister()"
                   [text]="'LANDING_MAIN.REGISTER_BTN' | translate" color="accent">
        </ui-button>
    </div>
</div>
<div class="footer footer__wrapper">
    <div class="footer__inner-wrapper">
        <div class="footer__social-container">
            <!-- icons list as mat-icons? -->
        </div>
        <div (click)="translationDocMatcher($event)" class="footer__text-container">
            <span class="footer__text"><span [innerHTML]="'LANDING_MAIN.FOOTER.DOCS' | translate | sanitizeHtml"></span></span>
        </div>
    </div>
</div>
