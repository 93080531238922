import {Injectable} from '@angular/core';
import {ParamMap} from '@angular/router';

@Injectable()
export class UuidService {
    private _uuid: string | undefined;

    get uuid() {
        return this._uuid;
    }

    checkUuid(params: ParamMap): void {
        const uuid = params.get('uuid');

        if (uuid) {
            this._uuid = uuid;
        }
    }
}
