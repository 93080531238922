<div [innerHTML]="'TEAM_BLOCK.TITLE' | translate"
     class="team-title"></div>
<div class="content-row team-card-content-row">
    <ng-container *ngFor="let card of cards">
        <div class="content-col">
            <div class="team-card">
                <img [src]="card.image" alt="" class="team-card__img">
                <div class="team-card__info">
                    <div [innerHTML]="card.name | translate"
                         class="team-card__info-name"></div>
                    <div [innerHTML]="card.role | translate"
                         class="team-card__info-role"></div>
                    <div [innerHTML]="card.description | translate"
                         class="team-card__info-description">
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</div>

