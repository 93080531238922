import {HttpErrorResponse} from '@angular/common/http';
import {Component, Inject, OnInit} from '@angular/core';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {ActivatedRoute} from '@angular/router';
import {AppSettingSDKModel, DocumentsSDKService} from '@libs/data-access/clanhall-sdk';
import {LoggerMessage, LoggerMessageTypeEnum, LoggerService} from '@libs/utils/logger';
import {
    ENV_TOKEN,
    Environment,
    ILandingEnvironmentSettings,
    translationDocHandler,
    Unsubscriber,
    UuidService,
} from '@libs/utils/shared';
import {Store} from '@ngxs/store';
import {takeUntil} from 'rxjs';

import {DocModalComponent, IDocModalData, IRegModalData, RegistrationModalComponent} from '../modals';
import {AppConfigSelectors} from '../store';
import {
  AnalyticsService,
} from '../../../../../clanhall/src/app/shared/services/analytics.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [Unsubscriber],
})
export class AppComponent implements OnInit {
    hasThaiPerson: boolean | undefined = false;



    constructor(
        private readonly dialog: MatDialog,
        private readonly store: Store,
        private readonly logger: LoggerService,
        private readonly documentsSDKService: DocumentsSDKService,
        @Inject(ENV_TOKEN) private readonly environment: Environment<ILandingEnvironmentSettings>,
        private readonly activatedRoute: ActivatedRoute,
        private readonly uuidService: UuidService,
        private readonly unsubscriber: Unsubscriber,
        private readonly analyticsService: AnalyticsService,
    ) {
        this.hasThaiPerson = this.environment.projectSettings.addedThaiPerson;
        this.analyticsService.init();
    }

    ngOnInit(): void {
        this.checkQueryParams();
    }

    emitRegister() {
        this.registerFn('landingStartRegBottom')();
    }

    registerFn(goalName: string): () => void {
        return () => {
            this.analyticsService.trackEventByGoalName(goalName);
            this.analyticsService.trackEventByGoalNameForYandex(goalName);
            const authMethods: AppSettingSDKModel[] = this.store.selectSnapshot(AppConfigSelectors.authMethods) || [];

            if (authMethods.length > 0) {
                this.dialog.open<RegistrationModalComponent, IRegModalData>(RegistrationModalComponent, {
                    data: {
                        authMethods,
                        openDocModalFn: this.openDocFn(),
                        goalName,
                    },
                    autoFocus: false,
                });
            } else {
                this.logger.log(
                    new LoggerMessage('Check active auth methods for lending! 2', {
                        type: LoggerMessageTypeEnum.InternalError,
                    }),
                );
                // TODO: Add user notification.
            }
        };
    }

    translationDocMatcher(event: MouseEvent) {
        translationDocHandler(event, this.openDocFn());
    }

    private checkQueryParams(): void {
        this.activatedRoute.queryParamMap.pipe(takeUntil(this.unsubscriber.destroy$)).subscribe(params => {
            this.uuidService.checkUuid(params);
        });
    }

    private openDocFn(): (docKey: IDocModalData['request']['key']) => void {
        return (docKey: IDocModalData['request']['key']) => {
            // Костыль в рамках DEV-289. Удалить после добавления нужного endpoint.
            if (docKey === 'company') {
                this.dialog.open<DocModalComponent, IDocModalData>(DocModalComponent, {
                    data: {
                        request: {
                            key: docKey,
                        },
                        document: {
                            name: 'Информация о компании',
                            body:
                                '<p>ИП Гилязов Марат Марселевич</p>' +
                                '<p>ОГРНИП 317774600250510</p>' +
                                '<p>Адрес для корреспонденции: 121351, г. Москва г, ул. Молодогвардейская, д. 30, к. 1, а/я 110</p>' +
                                '<p>Электронная почта: inbox@clanhall.net</p>',
                        },
                    },
                    autoFocus: false,
                });
            } else {
                this.documentsSDKService.returnDocument({key: docKey}).subscribe({
                    next: response => {
                        this.dialog.open<DocModalComponent, IDocModalData>(DocModalComponent, {
                            data: {
                                request: {
                                    key: docKey,
                                },
                                document: response,
                            },
                            autoFocus: false,
                        });
                    },
                    error: (error: unknown) => {
                        if (error instanceof HttpErrorResponse) {
                            this.logger.log(
                                new LoggerMessage(error.message, {
                                    type: LoggerMessageTypeEnum.InternalCriticalError,
                                    rawInfo: error,
                                }),
                            );
                        } else {
                            this.logger.log(
                                new LoggerMessage(JSON.stringify(error), {
                                    type: LoggerMessageTypeEnum.InternalCriticalError,
                                    rawInfo: error,
                                }),
                            );
                        }
                        // TODO: Add user notification.
                    },
                });
            }
        };
    }
}
