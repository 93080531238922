import {Component} from '@angular/core';

interface IGame {
    image: string;
}

@Component({
    selector: 'app-games-block',
    templateUrl: './games-block.component.html',
    styleUrls: ['./games-block.component.scss'],
})
export class GamesBlockComponent {
    gameImages: IGame[] = [
        {
            image: 'assets/img/arche_age_logo.png',
        },
        {
            image: 'assets/img/m_header_logo_bdo_global.png',
        },
        {
            image: 'assets/img/world_of_warcraft_logo.png',
        },
    ];
}
