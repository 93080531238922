import {ChangeDetectionStrategy, Component} from '@angular/core';
import {createBgLinkStrFunction} from '@libs/utils/shared';

interface IQuestion {
    title: string;
    list: string;
    image: string;
}

@Component({
    selector: 'app-questions-block',
    templateUrl: './questions-block.component.html',
    styleUrls: ['./questions-block.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuestionsBlockComponent {
    questions: IQuestion[] = [
        {
            title: 'QUESTIONS_BLOCK.QUESTION_1.TITLE',
            list: 'QUESTIONS_BLOCK.QUESTION_1.LIST',
            image: 'assets/question_one.png',
        },
        {
            title: 'QUESTIONS_BLOCK.QUESTION_2.TITLE',
            list: 'QUESTIONS_BLOCK.QUESTION_2.LIST',
            image: 'assets/question_two.png',
        },
    ];

    getImageCssUrl(imageLink: string): string {
        return createBgLinkStrFunction(imageLink);
    }
}
