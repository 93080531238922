import {ChangeDetectionStrategy, Component} from '@angular/core';
import {createBgLinkStrFunction} from '@libs/utils/shared';

interface IFeature {
    image: string;
    title: string;
    list: string;
    ctaStr: string;
}

@Component({
    selector: 'app-feature-block',
    templateUrl: './feature-block.component.html',
    styleUrls: ['./feature-block.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeatureBlockComponent {
    features: IFeature[] = [
        {
            image: 'assets/feature_one.png',
            title: 'FEATURE_BLOCK.FEATURE_1.TITLE',
            list: 'FEATURE_BLOCK.FEATURE_1.LIST',
            ctaStr: 'FEATURE_BLOCK.FEATURE_1.CTA_STR',
        },
        {
            image: 'assets/feature_two.png',
            title: 'FEATURE_BLOCK.FEATURE_2.TITLE',
            list: 'FEATURE_BLOCK.FEATURE_2.LIST',
            ctaStr: 'FEATURE_BLOCK.FEATURE_2.CTA_STR',
        },
    ];

    getImageCssUrl(imageLink: string): string {
        return createBgLinkStrFunction(imageLink);
    }
}
