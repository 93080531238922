import {
    HTTP_INTERCEPTORS,
    HttpEvent,
    HttpHandler,
    HttpHeaderResponse,
    HttpInterceptor,
    HttpProgressEvent,
    HttpRequest,
    HttpResponse,
    HttpSentEvent,
    HttpUserEvent,
} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {camelCase, isPlainObject, mapKeys, mapValues} from 'lodash-es';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

// #camelCase
@Injectable()
class KeyToCamelCaseInterceptor implements HttpInterceptor {
    intercept(
        request: HttpRequest<Record<string, any>>,
        next: HttpHandler,
    ): Observable<HttpEvent<Record<string, any>>> {
        const newReqBodySnakeCase: Record<string, any> | null = request.body
            ? this.mapKeysDeepLodash(request.body)
            : null;
        const newRequest: HttpRequest<Record<string, any>> = request.clone({
            body: newReqBodySnakeCase,
        });

        return next.handle(newRequest).pipe(
            map(
                (
                    event:
                        | HttpSentEvent
                        | HttpHeaderResponse
                        | HttpResponse<Record<string, any>>
                        | HttpProgressEvent
                        | HttpUserEvent<Record<string, any>>,
                ) => {
                    if (
                        event instanceof HttpResponse &&
                        typeof event.body === 'object' &&
                        !(event.url as string).includes('i18n')
                    ) {
                        const camelCaseObject: Record<string, any> | null = event.body
                            ? this.mapKeysDeepLodash(event.body)
                            : null;
                        const modEvent: HttpResponse<Record<string, any>> = event.clone({
                            body: camelCaseObject,
                        });

                        return modEvent;
                    }

                    if (event) {
                        return event;
                    }

                    return event;
                },
            ),
        );
    }

    private mapKeysDeepLodash(
        obj: Record<string, any>,
        callback: Function = (v: any, k: string) => camelCase(k),
        isRecursive: boolean = true,
    ): Record<string, any> {
        if (!obj && !isRecursive) {
            return {};
        }

        if (!isRecursive) {
            if (typeof obj === 'string' || typeof obj === 'number' || typeof obj === 'boolean') {
                return {};
            }
        }

        if (Array.isArray(obj)) {
            return obj.map((item: Record<string, any>) => {
                return this.mapKeysDeepLodash(item, callback, true);
            });
        }

        if (!isPlainObject(obj)) {
            return obj;
        }

        const result: Record<string, any> = mapKeys(obj, callback);

        return mapValues(result, (value: Record<string, any>) => {
            return this.mapKeysDeepLodash(value, callback, true);
        });
    }
}

export const KEY_TO_CAMEL_CASE_INTERCEPTOR_PROVIDER = {
    provide: HTTP_INTERCEPTORS,
    useClass: KeyToCamelCaseInterceptor,
    multi: true,
};
