import {Inject, Injectable} from '@angular/core';

import {LoggerMessage} from './logger-message.class';
import {LOGGER_PROVIDER_TOKEN} from './logger-provider.token';
import {AbstractLoggerProvider} from './providers';

@Injectable({providedIn: 'root'})
export class LoggerService {
    constructor(@Inject(LOGGER_PROVIDER_TOKEN) private readonly loggerProvider: AbstractLoggerProvider) {}

    log(...messages: LoggerMessage[]) {
        this.loggerProvider.logMassages(...messages);
    }
}
