import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatLegacyDialogModule as MatDialogModule} from '@angular/material/legacy-dialog';
import {BrowserModule} from '@angular/platform-browser';
import {RouterModule} from '@angular/router';
import {SanitizeHtmlPipe, UuidService} from '@libs/utils/shared';
import {TranslateModule} from '@ngx-translate/core';
import {ButtonModule} from 'primeng/button';

import {BasicButtonSComponent} from '../components';
import {CoreModule} from '../core/core.module';
import {AppComponent} from './app.component';
import {BenefitsBlockComponent} from './benefits-block/benefits-block.component';
import {FaqBlockComponent} from './faq-block/faq-block.component';
import {FeatureBlockComponent} from './feature-block/feature-block.component';
import {GamesBlockComponent} from './games-block/games-block.component';
import {PremierBlockComponent} from './premier-block/premier-block.component';
import {QuestionsBlockComponent} from './questions-block/questions-block.component';
import {RoadmapBlockComponent} from './roadmap-block/roadmap-block.component';
import {TeamBlockComponent} from './team-block/team-block.component';
import {WhyForBlockComponent} from './why-for-block/why-for-block.component';

@NgModule({
    declarations: [
        AppComponent,
        PremierBlockComponent,
        FeatureBlockComponent,
        QuestionsBlockComponent,
        BenefitsBlockComponent,
        GamesBlockComponent,
        WhyForBlockComponent,
        RoadmapBlockComponent,
        TeamBlockComponent,
        FaqBlockComponent,
    ],
    imports: [
        BrowserModule,
        CoreModule.forRoot(),
        RouterModule.forRoot([]),
        CommonModule,
        TranslateModule,
        HttpClientModule,
        BasicButtonSComponent,
        MatExpansionModule,
        MatDialogModule,
        SanitizeHtmlPipe,
        ButtonModule,
    ],
    providers: [UuidService],
    bootstrap: [AppComponent],
})
export class AppModule {}
