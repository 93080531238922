import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-premier-block',
    templateUrl: './premier-block.component.html',
    styleUrls: ['./premier-block.component.scss'],
})
export class PremierBlockComponent {
    /** Used only for stories */
    testTranslationsList = {
        mainText: '',
        subText: '',
        ctaButton: '',
    };

    @Input()
    registerFn!: () => unknown;
}
