<div class="title" [innerHTML]="'QUESTION_BLOCK.TITLE' | translate"></div>
<mat-accordion class="list" displayMode="flat">
    <ng-container *ngFor="let question of questions">
        <mat-expansion-panel class="list__item">
            <mat-expansion-panel-header
                    class="list__title">{{question.question | translate}}</mat-expansion-panel-header>
            <div class="list__text" [innerHTML]="question.answer | translate"></div>
        </mat-expansion-panel>
    </ng-container>
</mat-accordion>
