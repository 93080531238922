import {CommonModule} from '@angular/common';
import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {FormControl, ReactiveFormsModule, Validators} from '@angular/forms';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA} from '@angular/material/legacy-dialog';
import {
    AppSettingSDKModel,
    AppSettingSDKModelKeyEnum,
    AuthSDKService,
    RegisterRequestParams,
} from '@libs/data-access/clanhall-sdk';
import {LoggerMessage, LoggerMessageTypeEnum, LoggerService} from '@libs/utils/logger';
import {SanitizeHtmlPipe, translationDocHandler, UuidService, WINDOW} from '@libs/utils/shared';
import {TranslateModule} from '@ngx-translate/core';
import {InputTextModule} from 'primeng/inputtext';
import {BehaviorSubject, Observable} from 'rxjs';

import {BasicButtonSComponent} from '../../components';
import {RegisterByEmailComponent} from './components/login-by-email/register-by-email.component';
import {IRegModalData} from './registration-modal-data.inteface';
import {
  AnalyticsService,
  GoalNameEnum,
} from '../../../../../../clanhall/src/app/shared/services/analytics.service';

@Component({
    selector: 'ui-registration-modal',
    templateUrl: './registration-modal.component.html',
    styleUrls: ['./registration-modal.component.scss'],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        TranslateModule,
        BasicButtonSComponent,
        CommonModule,
        SanitizeHtmlPipe,
        ReactiveFormsModule,
        InputTextModule,
        RegisterByEmailComponent,
    ],
})
export class RegistrationModalComponent {
    authMethods: AppSettingSDKModel[] = [];

    email: FormControl = new FormControl(undefined, [Validators.required, Validators.email]);

    private readonly registerSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    registered$: Observable<boolean> = this.registerSubject.asObservable();

    constructor(
        @Inject(MAT_DIALOG_DATA) readonly data: IRegModalData,
        private readonly logger: LoggerService,
        @Inject(WINDOW) private readonly window: Window,
        private readonly authSDKService: AuthSDKService,
        private readonly UuidService: UuidService,
        private readonly analyticsService: AnalyticsService,

    ) {
        const actualAuthMethods = data.authMethods
            .filter(method => {
                return method.enabled;
            })
            .filter(method => {
                return method.key === AppSettingSDKModelKeyEnum.emailToken;
            });

        if (actualAuthMethods.length > 0) {
            this.authMethods = actualAuthMethods;
        } else {
            this.logger.log(
                new LoggerMessage('Check active auth methods for lending! 1', {
                    type: LoggerMessageTypeEnum.DevCriticalError,
                }),
            );
            // TODO: Add user notification.
        }
    }

    translationDocHandler(event: MouseEvent) {
        translationDocHandler(event, this.data.openDocModalFn);
    }

    goRegistration(method: AppSettingSDKModel): void {
        if (method.key === AppSettingSDKModelKeyEnum.registerEmailPassword) {
            // TODO: email login link
        } else if (method.key === AppSettingSDKModelKeyEnum.discord) {
            if (method.value) {
                this.window.document.location.href = method.value;
            } else {
                this.logger.log(
                    new LoggerMessage('No link in auth provider!', {
                        type: LoggerMessageTypeEnum.InternalCriticalError,
                    }),
                );
                // TODO: Add user notification
            }
        }
    }

    registerByEmail({email}: {email: string}): void {
        const {uuid} = this.UuidService;
        const params: RegisterRequestParams = {
            email,
            uuid: uuid ?? undefined,
            offer: true,
            privacy: true,
        };

      switch (this.data.goalName) {
        case 'landingStartRegTop':
          this.analyticsService.trackEventByGoalName(GoalNameEnum.landingSendReg);
          this.analyticsService.trackEventByGoalNameForYandex(GoalNameEnum.landingSendRegTopYm);
          break;
        case 'landingStartRegMiddle':
          this.analyticsService.trackEventByGoalName(GoalNameEnum.landingSendReg);
          this.analyticsService.trackEventByGoalNameForYandex(GoalNameEnum.landingSendRegMiddleYm);
          break;
        case 'landingStartRegBottom':
          this.analyticsService.trackEventByGoalName(GoalNameEnum.landingSendReg);
          this.analyticsService.trackEventByGoalNameForYandex(GoalNameEnum.landingSendRegBottomYm);
      }

        this.authSDKService.register(params).subscribe(() => this.registerSubject.next(true));
    }
}
