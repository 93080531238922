import {Injectable} from '@angular/core';
import {AppSDKService, AppSettingsSDKModel} from '@libs/data-access/clanhall-sdk';
import {undefined$} from '@libs/utils/shared';
import {Action, State, StateContext} from '@ngxs/store';
import {map, Observable, switchMap} from 'rxjs';

import {AppConfigActions} from './app-config.actions';
import {IAppConfigState} from './app-config-state.interface';

@State<IAppConfigState>({
    name: 'appConfig',
    defaults: {
        cmsSettings: {
            auth: [],
        },
    },
})
@Injectable()
export class AppConfigState {
    constructor(private readonly appSDKService: AppSDKService) {}

    @Action(AppConfigActions.GetAppConfig)
    getAppConfig(context: StateContext<IAppConfigState>): Observable<void> {
        return this.appSDKService.applicationSettings().pipe(
            switchMap((response: AppSettingsSDKModel) => {
                return context.dispatch(new AppConfigActions.SetAppConfig(response));
            }),
            map(() => undefined),
        );
    }

    @Action(AppConfigActions.SetAppConfig)
    setAppConfig(context: StateContext<IAppConfigState>, {payload}: AppConfigActions.SetAppConfig): Observable<void> {
        context.patchState({
            cmsSettings: payload,
        });

        return undefined$();
    }
}
