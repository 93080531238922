<ng-container *ngFor="let question of questions; let odd = odd">
    <div class="question__container">
        <div class="question__image" [ngStyle]="{'background-image': getImageCssUrl(question.image)}"
             [ngClass]="odd ? 'question__image_left' : 'question__image_right'"></div>
        <div class="question__text-container">
            <div class="question__inner-text-container">
                <div class="question__title" [innerHTML]="question.title | translate"></div>
                <ul class="question__list" [innerHTML]="question.list | translate"></ul>
            </div>
        </div>
    </div>
</ng-container>