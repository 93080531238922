import {Directive, Input, OnInit} from '@angular/core';
import {ThemePalette} from '@angular/material/core';

@Directive()
export class UiCommonButtonDirective implements OnInit {
    @Input()
    color: ThemePalette = 'primary';

    ngOnInit(): void {}
}
