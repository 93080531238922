<div [innerHTML]="'FEATURES_BLOCK.TITLE' | translate" class="features-title"></div>
<div class="content-row features-content-row">
    <ng-container *ngFor="let feature of features">
        <div class="content-col features-content-col">
            <div [innerHTML]="feature.subtitle | translate" class="features-subtitle"></div>
            <ul [innerHTML]="feature.list | translate" class="features-list">
            </ul>
        </div>
    </ng-container>
    <div class="content-col features-content-col mobile-hide"><img alt="" class="features-img"
                                                                   src="assets/img/сaesar.png"></div>
    <div [innerHTML]="'FEATURES_BLOCK.DESCRIPTION' | translate" class="features-description"></div>
</div>
