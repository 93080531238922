<ng-container [ngSwitch]="type">
    <ng-container *ngSwitchCase="'basic'">
        <button [color]="color" mat-button [class]="{'stretch': stretch === 'true'}">
            {{text}}
        </button>
    </ng-container>
    <ng-container *ngSwitchCase="'raised'">
        <button [color]="color" mat-raised-button [class]="{'stretch': stretch === 'true'}">
            {{text}}
        </button>
    </ng-container>
    <ng-container *ngSwitchCase="'flat'">
        <p-button styleClass="p-button-filled--blue p-button-l" label="{{text}}" ></p-button>
    </ng-container>
    <ng-container *ngSwitchCase="'stroked'">
        <p-button styleClass="p-button-outlined p-button-outlined--white p-button-l" label="{{text}}" ></p-button>
    </ng-container>
    <ng-container *ngSwitchDefault>
        Button wrapperType is incorrect!
    </ng-container>
</ng-container>
