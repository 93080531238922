import {ChangeDetectionStrategy, Component, Inject, OnInit} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {
    MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
    MatLegacyDialogModule as MatDialogModule,
} from '@angular/material/legacy-dialog';
import {LoggerMessage, LoggerMessageTypeEnum, LoggerService} from '@libs/utils/logger';

import {SquareIconButtonComponent} from '../../components';
import {ModalHeaderComponent} from '../modal-header/modal-header.component';
import {IDocModalData} from './doc-modal-data.interface';

@Component({
    selector: 'ui-doc-modal',
    templateUrl: './doc-modal.component.html',
    styleUrls: ['./doc-modal.component.scss'],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [MatDialogModule, SquareIconButtonComponent, MatIconModule, ModalHeaderComponent],
})
export class DocModalComponent implements OnInit {
    title: string;
    desc: string;
    body: string;

    constructor(@Inject(MAT_DIALOG_DATA) readonly data: IDocModalData, private readonly logger: LoggerService) {
        this.checkDocFields(data.document);
        this.title = data.document.name || '';
        this.desc = data.document.desc || '';
        this.body = data.document.body || '';
    }

    ngOnInit(): void {}

    private checkDocFields(doc: IDocModalData['document']) {
        const errorPattern = (fieldName: string) => `Empty doc field: ${fieldName}`;

        if (!doc.name) {
            this.logger.log(
                new LoggerMessage(errorPattern('name'), {type: LoggerMessageTypeEnum.InternalError, rawInfo: doc}),
            );
        }

        if (!doc.desc) {
            this.logger.log(
                new LoggerMessage(errorPattern('desc'), {type: LoggerMessageTypeEnum.InternalWarn, rawInfo: doc}),
            );
        }

        if (!doc.body) {
            this.logger.log(
                new LoggerMessage(errorPattern('body'), {
                    type: LoggerMessageTypeEnum.InternalCriticalError,
                    rawInfo: doc,
                }),
            );
        }
    }
}
