import {Selector} from '@ngxs/store';

import {AppConfigState} from './app-config.state';
import {IAppConfigState} from './app-config-state.interface';

export class AppConfigSelectors {
    @Selector([AppConfigState])
    static state(state: IAppConfigState) {
        return state;
    }

    @Selector([AppConfigState])
    static authMethods(state: IAppConfigState) {
        return state.cmsSettings.auth;
    }

    @Selector([AppConfigState])
    static cmsSettings(state: IAppConfigState): IAppConfigState['cmsSettings'] {
        return state.cmsSettings;
    }
}
