<div [innerHTML]="'BENEFITS_BLOCK.TITLE' | translate"
     class="benefits-title"></div>
<div class="content-row">
    <ng-container *ngFor="let benefit of benefits">
        <div class="content-col benefits-content-col">
            <div [innerHTML]="'BENEFITS_BLOCK.SUBTITLE' | translate"
                 class="benefits-subtitle"></div>
            <div [innerHTML]="benefit.subTitle | translate"
                 class="benefits-subtitle benefits-subtitle--white"></div>
            <ul [innerHTML]="benefit.list | translate"
                class="benefits-list"></ul>
        </div>
    </ng-container>
</div>
<div class="benefits-button-container">
    <ui-button class="benefits-button-container__btn" [text]="'BENEFITS_BLOCK.CTA_BUTTON' | translate"
               color="accent" (click)="registerFn()"></ui-button>
</div>

